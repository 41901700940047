import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts'
import CommonHero from '../components/commonhero/commonhero.js'

const NotFound = () => (
  <Layout head={{ title: 'NOT FOUND' }}>
    <CommonHero pageTitle="404 PAGE NOT FOUND" />
    <main className="bg-white">
      <div className="thank-you-section component">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-11 mx-auto">
              <div className="text-center">
                <h5>
                  You just hit a route that doesn&#39;t exist... the sadness.
                </h5>
                <div className="d-flex justify-content-center mt-4">
                  <Link className="alink hvr-shadow" to="/">
                    Return Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default NotFound
